import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import { H1 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import PageLayout, { SideBarLayout } from 'lux/components/PageLayout';
import Pagination from 'lux/components/Pagination';
import VacancySearchItem from 'lux/components/VacancySearchItem';
import Source from 'lux/components/VacancySearchItem/types/Source';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import VacancyFavoriteSidebar from 'lux/pages/VacancyFavorite/components/Sidebar';

const VacancyFavoritePage = ({ trls }) => {
    const vacancies = useSelector((state) => state.favoriteVacancies?.vacancies);
    const paging = useSelector((state) => state.favoriteVacancies?.paging);
    const title = trls[VacancyFavoritePage.trls.title];
    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;

    return (
        <PageLayout title={title} layout={SideBarLayout} sidebar={VacancyFavoriteSidebar}>
            <Column xs="4" s="8" m="8" l="11">
                <H1>{title}</H1>
                <VSpacing base={6} />
            </Column>
            <Column xs="4" s="8" m="8" l="11">
                <Gap bottom>
                    {vacancies?.map((vacancy) => (
                        <VacancySearchItem
                            key={vacancy.vacancyId}
                            vacancy={vacancy}
                            vacancySource={Source.VacancySearch}
                            isXs={isXs}
                        />
                    ))}
                    {paging && (
                        <Gap top>
                            <Pagination {...paging} />
                        </Gap>
                    )}
                    {!vacancies?.length && (
                        <>
                            <p>{trls[VacancyFavoritePage.trls.emptyTitle]}</p>
                            <p>{trls[VacancyFavoritePage.trls.emptyText]}</p>
                        </>
                    )}
                </Gap>
            </Column>
        </PageLayout>
    );
};

VacancyFavoritePage.propTypes = {
    trls: PropTypes.object,
};

VacancyFavoritePage.trls = {
    title: 'favoriteVacancyes.title',
    emptyTitle: 'vacancySearchResults.noResult',
    emptyText: 'vacancySearchResults.noResult.description',
};

export default translation(VacancyFavoritePage);
